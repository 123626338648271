import React from "react"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Head from "../components/head"
import previsioniPageStyles from "./previsioni.module.scss"
const ScrollReveal = loadable(() => import("../components/scrollReveal"))

const PrevisioniPage = () => {
  return (
    <Layout footerTopMargin="0px" showStepScroller={false}>
      <Head title="Previsioni" />
      <ScrollReveal options={{ duration: 1000, origin: "bottom" }}>
        <section id="previsioniMeteo" className={previsioniPageStyles.content}>
          <iframe
            title="Previsioni meteo by Windy.com"
            width="100%"
            height="100%"
            src="https://embed.windy.com/embed2.html?lat=44.340&lon=10.360&zoom=8&level=surface&overlay=wind&menu=&message=&marker=&calendar=&pressure=&type=map&location=coordinates&detail=true&detailLat=44.384&detailLon=10.348&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1"
            frameBorder="0"
          ></iframe>
        </section>
      </ScrollReveal>
    </Layout>
  )
}

export default PrevisioniPage
